.imagemLogo {
    width: 40px;
    height: 40px;
}

a {
    text-decoration: none;
    color: var(--cor-background-div);
    background-color: var(--cor-font);
    border: 1px solid var(--cor-font);
    border-radius: 15px;
    padding: 10px;
}

a:hover {
    color: var(--cor-font);
    background-color: var(--cor-hover);
    border: 1px solid var(--cor-font);
    transition: 0.5s;
}

li {
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

