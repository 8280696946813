/*CLASSES*/
#container {
    margin: 10px 0px;
    padding: 10px;
    text-align: center;
}

#container__subtitle {
    font-size: 1em;
    font-weight: 400;
}

#fotoPerfil {
    width: 200px;
    height: 200px;
}
