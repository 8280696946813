#emailLink {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-style: italic;
}

.imagemEmail {
    width: 20px;
    height: 20px;
}