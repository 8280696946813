/*IMPORTANDO AS FONTES*/
@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/*
PALETA DE CORES DO PROJETO
    #BCB8B1 -> COR PRIMARIA (BACKGROUND)
    #F4F3EE -> COR BACKGROUND DIV
    #8A817C -> COR FONTE
    #E0AFA0 -> COR DESTAQUE
    #B8B8B8 -> COR HOVER
*/

/*VARIAVEIS CSS*/
:root {
  --cor-background: #BCB8B1;
  --cor-background-div: #F4F3EE;
  --cor-font: #8A817C;
  --cor-destaque: #E0AFA0;
  --cor-hover: #B8B8B8;
  --element-shadow: 5px 5px 30px #463F3A;
}

/*RESET CSS*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--cor-background);
  color: var(--cor-font);
  box-sizing: border-box;
  font-family: 'Afacad', sans-serif;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
