main {
    width: 45vw;
    max-width: 600px;
    min-width: 400px;
    margin: 10px 0px 10px 0;
    padding: 10px;
    background-color: var(--cor-background-div);
    border-radius: 30px;
    box-shadow: var(--element-shadow);
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}